<template>
  <b-modal
    @shown="updateItem"
    title="學生服務階段資訊"
    id="studentServiceInfoMessage"
    size="xl"
  >
    <b-container fluid>
      <header class="dtc-grid-header my-dark">
        <div
          v-for="(item, i) in headers"
          :key="`headers${i}`"
          :title="item"
          style="background:#424242;color:#fff"
        >
          {{ item }}
        </div>
      </header>
      <main
        v-if="!items.length"
        class="mt-2"
        style="grid-column: 1/-1;font-size:26px;text-align:center;font-weight:600"
      >
        暫無資料
      </main>
      <main
        class="dtc-grid-header2"
        v-for="(item, i) in items"
        :key="i"
        style="color:#39312E;"
        :style="
          i % 2 == 0
            ? 'background-color: #EDEDED;'
            : 'background-color: #DDDDDD;'
        "
      >
        <div :title="item.StudentName">{{ item.StudentName || "" }}</div>
        <div :title="item.Identifier">{{ item.Identifier || "" }}</div>
        <div :title="item.ServiceItem">{{ item.ServiceItem || "" }}</div>
        <div :title="item.Remark">{{ item.Remark || "" }}</div>
        <div :title="item.StartDate">{{ item.StartDate || "" }}</div>
        <div :title="item.ExpectedDate">{{ item.ExpectedDate || "" }}</div>
      </main>
      <footer class="dtx-footer">
        <b-pagination
          v-model="currentPageNum"
          :total-rows="rowCount"
          :per-page="pagingRowPerPage"
          aria-controls="dataTable1"
          align="center"
          class="mt-2"
        >
          <span slot="first-text">
            <i class="fas fa-fast-backward"></i>
          </span>
          <span slot="prev-text">
            <i class="fas fa-step-backward"></i>
          </span>
          <span slot="next-text">
            <i class="fas fa-step-forward"></i>
          </span>
          <span slot="last-text">
            <i class="fas fa-fast-forward"></i>
          </span>
          <span slot="page" slot-scope="{ page, active }">
            <b v-if="active">{{ page }}</b>
            <i v-else>{{ page }}</i>
          </span>
        </b-pagination>
        <b-select
          :options="rows"
          class="mt-2"
          v-model="pagingRowPerPage"
        ></b-select>
        <div class="mt-3">{{ totalCountStr }}</div>
      </footer>
    </b-container>

    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right ml-3 mr-3"
          @click="$bvModal.hide('studentServiceInfoMessage')"
          >關閉</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
const headers = [
  "公費生姓名",
  "身分證字號",
  "服務現況",
  "通知原因",
  "起始日期",
  "逾時日期",
];

import { store } from "@/store/global.js";
import buildQuery from "odata-query";
const rows = [10, 20, 50];
export default {
  name: "studentServiceInfoMessage",
  data() {
    return {
      personinfo: {},
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      headers,
      items: [],
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 10,
      rows,
      totalCountStr: "",
      isAdminCodeCheck: false,
      isSchoolCodeCheck: false,
      isCanView: false,
    };
  },
  computed: {},
  methods: {
    setCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const roleOtal = JSON.parse(sessionStorage.getItem("roleOtal"));

      this.isAdminCodeCheck = roleOtal.includes(loginHealthCode) ? true : false;
      const stuChool = JSON.parse(sessionStorage.getItem("stuChool"));
      this.isSchoolCodeCheck = stuChool.includes(loginHealthCode)
        ? true
        : false;
      const all = [...roleOtal, ...stuChool];
      this.isCanView = all.includes(loginHealthCode) ? true : false;
    },
    async updateItem() {
      this.currentPageNum = 1;
      this.rowCount = 0;
      this.pagingRowPerPage = 10;
      await this.setCheck();
      await this.getData();
    },
    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const obj = { top, skip };
      let qs = buildQuery(obj);

      if (!this.isCanView) {
        return;
      }

      let id = "";
      if (this.isSchoolCodeCheck) {
        id = `${window.sessionStorage.docrkkt}`.replace(/\"/g, "");
        qs = `${qs}&$filter=Identifier eq '${id}'`;
      }

      const url = `api/studentServiceInfo${qs}`;

      try {
        const { Items, Count } = await window.axios.get(url);
        this.items = JSON.parse(JSON.stringify(Items));
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        console.log(e);
      }
    },
  },

  mounted() {},
  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 140px 140px 140px 1fr 120px 120px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    // word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    word-break: keep-all;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-top: 1px solid #dee2e5;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 0;
    line-height: 46px;
    height: 46px;
  }
  .fee {
    text-align: right;
    padding-right: 10px;
  }
}
p {
  font-size: 2em;
  text-align: center;
}
.input-group-text {
  width: 100px;
  background: #0379fd;
  color: #fff;
}

div.input-group.special-col > div.input-group-prepend > div.input-group-text {
  width: 130px;
}
div.input-group.dtc-bg > div.input-group-prepend > div.input-group-text {
  background: #646969;
  color: #ffffff;
}

.input-group {
  margin-bottom: 10px;
}

::v-deep
  #studentServiceInfoMessage
  > .modal-dialog
  > .modal-content
  > .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
// ::v-deep .modal-content {
//   margin-left: -180px;
//   width: 136%;
// }
.form-control[readonly] {
  background-color: #ffffff;
}
hr.new {
  border-top: 1px dashed #cacaca;
}
h4.title {
  font-size: 20px;
  font-weight: 700;
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}
</style>
